import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { useAuth } from './../contexts/auth';

// Components
import Header from './../components/Header';

import * as styles from './../styles/PageStyles';

interface Ticket {
  type: 'single'|'double',
  upgrades: {
    fasttrack: boolean,
    dining: boolean,
    breakfast: boolean,
  },
  application_time: string,
  status: 'internal'|'alumni'|'external'|'1888',
}

interface User {
  _id: string;
  first_name: string,
  last_name: string,
  email: string,
  status: string,
  college?: string,
  crsid?: string,
  tickets: {
    applied: Ticket[],
    allocated: Ticket[],
    accepted: Ticket[],
  },
  payment_reference: string,
}

const Users = () => {
  const { user, request } = useAuth();

  const [users, setUsers] = useState<User[]>([]);

  const getData = async () => {
    const res = await request('/users', 'GET');
    const data = await res.json();
    setUsers(data);
  }

  useEffect(() => {
    getData();
  }, []);

  return <>
    <styles.PageWrapper>
      <Header />

      <styles.Users id="users">
        <h3>All users:</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Tickets applied for</th>
              <th>Tickets allocated</th>
              <th>Payment reference</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => <React.Fragment key={user._id}>
              <tr>
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.status[0].toUpperCase() + user.status.substring(1)}</td>
                <td>{user.tickets?.applied?.reduce((prev,curr)=>prev+(curr.type === "single" ? 1 : 2),0)}</td>
                <td>{user.tickets.allocated.length}</td>
                <td>{user.payment_reference}</td>
                <td><Link to={`/user/${user._id}`}>Details</Link></td>
              </tr>
            </React.Fragment>)}
          </tbody>
        </table>
      </styles.Users>
    </styles.PageWrapper>
  </>;
}

export default Users;