import React, { useEffect, useState } from 'react';

import { useAuth } from './../contexts/auth';

// Components
import Header from './../components/Header';

import * as styles from './../styles/PageStyles';

interface User {
  id: string;
  first_name: string,
  last_name: string,
  email: string,
  affiliation: string,
  verification: string
}

const ClubVerification = () => {
  const { request } = useAuth();

  const [users, setUsers] = useState<User[]>([]);

  const getData = async () => {
    const res = await request('/club/unverified', 'GET');
    const data = await res.json();
    setUsers(data);
  }

  const verifyUser = async (id: string) => {
    const res = await request(`/club/verify/${id}`, 'POST');
    if (res.ok) {
      getData();
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return <>
    <styles.PageWrapper>
      <Header />

      <styles.Users id="users">
        <h3>Unverified 1888 Club users:</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Affiliation</th>
              <th>Verification (crsid/graduation year)</th>
              <th>Verify</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => <React.Fragment key={user.id}>
              <tr>
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.affiliation}</td>
                <td>{user.verification}</td>
                <td><styles.SubtleButton onClick={() => verifyUser(user.id)}>Verify</styles.SubtleButton></td>
              </tr>
            </React.Fragment>)}
          </tbody>
        </table>
      </styles.Users>
    </styles.PageWrapper>
  </>;
}

export default ClubVerification;