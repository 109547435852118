import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

import * as styles from './Header.styles';

const Header = () => {
  const { user } = useAuth();
  return <>
  <div>
    <styles.Header id="header">
      <div className="info">
        <h1>St John's May Ball Admin</h1>
        <p>Logged in as <b>{user.email}</b></p>
      </div>
      <Link to={'/logout'}>Sign Out</Link>
    </styles.Header>

    <styles.Nav>
      <NavLink to={'/'}>Home</NavLink>
      <NavLink to={'/stats'}>Stats</NavLink>
      <NavLink to={'/users'}>Users</NavLink>
      <NavLink to={'/accepted'}>Accepted</NavLink>
      <NavLink to={'/add_user'}>Add user</NavLink>
      <NavLink to={'/fellow_verification'}>Fellow verification</NavLink>
      <NavLink to={'/club_verification'}>1888 verification</NavLink>
      {/* <Link to={'/finance'}>Finance</Link> */}
    </styles.Nav>
    </div>
  </>;
}

export default Header;