import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: block;

    a {
      display: inline-block;
      margin-top: 0.5rem;
    }
  }

  margin-bottom: 1rem;
  padding-bottom: 1rem;

  border-bottom: 1px solid ${props => props.theme.colors.primary};
  
  h1 {
    color: ${props => props.theme.colors.primary};
  }

  a {
    font-size: ${props => props.theme.fontSizes.small};
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.background};

    text-decoration: none;

    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    padding: 0.5rem;

    outline: none;

    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.background};
    }
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.primary};

  a {
    padding: 0 1rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.heading};
    color: ${props => props.theme.colors.primary};

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      filter: brightness(0.6);
    }
    
  }
`;