import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { useAuth } from './../contexts/auth';

// Components
import Header from './../components/Header';

import * as styles from './../styles/PageStyles';

const Stats = () => {
  const { user } = useAuth();
  return <>
    <styles.PageWrapper>
      <Header />
    </styles.PageWrapper>
  </>;
}

export default Stats;