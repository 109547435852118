import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import Select, { SingleValue, Theme as SelectTheme } from 'react-select';

import { Theme } from '../styles/Theme';
import { useAuth } from './../contexts/auth';

// Components
import Header from './../components/Header';
import TicketGrid from './../components/TicketGrid';

import * as styles from './../styles/PageStyles';

interface Ticket {
  type: 'single'|'double',
  upgrades: {
    fasttrack: boolean,
    dining: boolean,
    breakfast: boolean,
  },
  application_time: string,
  status: 'internal'|'alumni'|'external'|'1888',
}

interface User {
  _id: string;
  first_name: string,
  last_name: string,
  email: string,
  status: string,
  college?: string,
  crsid?: string,
  tickets: {
    applied: Ticket[],
    allocated: Ticket[],
    accepted: Ticket[],
  }
}

const selectTheme = (theme: SelectTheme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: Theme.colors.primaryFaded,
    primary: Theme.colors.primary,
  },
});

const User = () => {
  const { user, request } = useAuth();

  const [userData, setUserData] = useState<User>({} as User);

  const [showAddTicketsModal, setShowAddTicketModal] = useState(false);

  const { id } = useParams();

  const getData = async () => {
    const res = await request(`/user/${id}`, 'GET');
    const data = await res.json();
    setUserData(data);
  }

  useEffect(() => {
    getData();
  }, []);

  const [ticketType, setTicketType] = useState({value: 'double', label: 'Double'} as SingleValue<{ value: string; label: string; }>);
  const [ticketStatus, setTicketStatus] = useState({} as SingleValue<{ value: string; label: string; }>);
  const [fastTrack, setFastTrack] = useState({} as SingleValue<{ value: string; label: string; }>);
  const [dining, setDining] = useState({} as SingleValue<{ value: string; label: string; }>);
  const [breakfast, setBreakfast] = useState({} as SingleValue<{ value: string; label: string; }>);
  const [price, setPrice] = useState(0);
  const [paid, setPaid] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [modalError, setModalError] = useState('');

  const addTickets = async () => {
    // Create tickets object
    const newTickets: any = {...userData.tickets};

    if (ticketStatus!.value === 'applied') {
      newTickets.applied.push({
        type: ticketType!.value || 'double',
        application_time: new Date(),
        status: userData.status,
        upgrades: {
          fasttrack: fastTrack!.value === 'true',
          dining: dining!.value === 'true',
          breakfast: breakfast!.value === 'true',
        }
      });
    }

    if (ticketStatus!.value === 'allocated') {
      newTickets.allocated.push({
        type: ticketType!.value || 'double',
        allocation_time: new Date(),
        status: userData.status,
        upgrades: {
          fasttrack: fastTrack!.value === 'true',
          dining: dining!.value === 'true',
          breakfast: breakfast!.value === 'true',
        },
        price: price,
        paid: paid,
      });
    }

    if (ticketStatus!.value === 'accepted') {
      newTickets.accepted.push({
        type: ticketType!.value || 'double',
        acceptance_time: new Date(),
        status: userData.status,
        upgrades: {
          fasttrack: fastTrack!.value === 'true',
          dining: dining!.value === 'true',
          breakfast: breakfast!.value === 'true',
        },
        price: price,
        paid: paid,
      });
    }

    // Update user
    setIsLoading(true);
    const res = await request(`/user/${id}`, 'PUT', {
      tickets: newTickets
    });

    if (res.ok) {
      setShowAddTicketModal(false);
      getData();
    } else {
      setModalError('Error adding ticket');
    }

    setIsLoading(false);
  }

  const removeTicket = async (status: string, i: number) => {
    const newTickets: any = {...userData.tickets};

    newTickets[status].splice(i, 1);

    setIsLoading(true);
    const res = await request(`/user/${id}`, 'PUT', {
      tickets: newTickets
    });

    if (res.ok) {
      getData();
    } else {
      setModalError('Error removing ticket');
    }

    setIsLoading(false);
  }

  const [genericEmailSent, setGenericEmailSent] = useState(false);

  const sendGenericUpdateEmail = async () => {
    console.log('triggered');
    const res = await request(`/user/${id}/email/generic`, 'GET');

    if (res.ok) {
      setGenericEmailSent(true);
    }
  }

  return <>
    <styles.PageWrapper>
      <Header />
      <styles.Apply>
        <h3>{userData.first_name} {userData.last_name}</h3>
        <p className="cap-first">{userData.status} at {userData.college || "St John's College"}</p>
        <p><a href={`mailto:${userData.email}`}>{userData.email}</a></p>
      </styles.Apply>

      <styles.Info>
        <h3>Action centre:</h3>
        <div className="button-group">
          <styles.SubtleButton 
            onClick={() => sendGenericUpdateEmail()}
          >Send generic "Account updated" email</styles.SubtleButton>
        </div>
        { genericEmailSent && <p>Successfully sent email</p> }
      </styles.Info>

      <TicketGrid
        tickets={userData.tickets?.applied || []}
        ticketGroup={'applied'}
        buttons={[
          { text: 'Remove', fn: (i: number) => removeTicket('applied', i) },
        ]}
      />
      <styles.SubmitButton
        onClick={() => {
          setTicketStatus({value: 'applied', label: 'Ticket application'});
          setShowAddTicketModal(true);
        }
      }>
        Add tickets
      </styles.SubmitButton>
      
      <TicketGrid
        tickets={userData.tickets?.allocated || []}
        ticketGroup={'allocated'}
        buttons={[
          { text: 'Remove', fn: (i: number) => removeTicket('allocated', i) },
        ]}
      />
      <styles.SubmitButton
        onClick={() => {
          setTicketStatus({value: 'allocated', label: 'Ticket allocation'});
          setShowAddTicketModal(true);
        }
      }>
        Add tickets
      </styles.SubmitButton>

      <TicketGrid
        tickets={userData.tickets?.accepted || []}
        ticketGroup={'accepted'}
        buttons={[
          { text: 'Remove', fn: (i: number) => removeTicket('accepted', i) },
        ]}
      />
      <styles.SubmitButton
        onClick={() => {
          setTicketStatus({value: 'accepted', label: 'Ticket acceptance'});
          setShowAddTicketModal(true);
        }
      }>
        Add tickets
      </styles.SubmitButton>
      
    </styles.PageWrapper>

    {showAddTicketsModal && <>
      <styles.ModalWrapper>
        <styles.ModalBox>
          <styles.ModalHeader>
            <h3>St John's College May Ball Admin</h3>
            <h2>Add ticket to account</h2>
            <styles.CloseButton onClick={()=>setShowAddTicketModal(false)}>Close</styles.CloseButton>
          </styles.ModalHeader>
          <styles.Divider />
          <styles.ModalBody>
            <h3>Ticket details:</h3>
            <label htmlFor={'type'}>Ticket type:</label>

            <Select
              defaultValue={ticketType}
              name={'type'}
              onChange={setTicketType}
              theme={selectTheme}
              options={[
                { value: 'single', label: 'Single' },
                { value: 'double', label: 'Double' },
              ]}
            />
            
            <label htmlFor={'type'}>Ticket status:</label>

            <Select
              defaultValue={ticketStatus}
              name={'type'}
              onChange={setTicketStatus}
              theme={selectTheme}
              options={[
                { value: 'applied', label: 'Ticket application' },
                { value: 'allocated', label: 'Ticket allocation' },
                { value: 'accepted', label: 'Accepted ticket allocation' },
              ]}
            />

            <h3>Upgrades:</h3>
            <p>Note: can only apply either fast-track, or dining due to timings</p>
            <label htmlFor={'fasttrack'}>Fast Track:</label>
            <Select
              defaultValue={fastTrack}
              name={'fasttrack'}
              onChange={setFastTrack}
              theme={selectTheme}
              options={[
                { value: 'false', label: 'No Fast Track Upgrade' },
                { value: 'true', label: 'Fast Track Upgrade' },
              ]}
            />

            <label htmlFor="dining">Dining:</label>
            <Select
              defaultValue={dining}
              name={'dining'}
              onChange={setDining}
              theme={selectTheme}
              options={[
                { value: 'false', label: 'No Dining Upgrade' },
                { value: 'true', label: 'Dining Upgrade' },
              ]}
            />

            <label htmlFor="breakfast">Breakfast:</label>
            <Select
              defaultValue={breakfast}
              name={'breakfast'}
              onChange={setBreakfast}
              theme={selectTheme}
              options={[
                { value: 'false', label: 'No Breakfast Upgrade' },
                { value: 'true', label: 'Breakfast Upgrade' },
              ]}
            />

            { (ticketStatus!.value === 'allocated' || ticketStatus!.value === 'accepted') && <>
              <h3>Price</h3>
              <p>Some tickets have already been paid for, or are available at a specific price.</p>

              <label htmlFor="price">Price:</label>
              <input
                type="number"
                name="price"
                value={price}
                onChange={e => setPrice(Number(e.target.value))}
              />

              <label htmlFor="paid">Amount paid:</label>
              <input
                type="number"
                name="paid"
                value={paid}
                onChange={e => setPaid(Number(e.target.value))}
              />
            </>}

            
            { modalError && <p className="error">{modalError}</p> }

            <styles.SubmitButton
              color={'primary'}
              onClick={() => addTickets()}
              disabled={isLoading}
            >
              Submit
            </styles.SubmitButton>


          </styles.ModalBody>
        </styles.ModalBox>
      </styles.ModalWrapper>

    </>}
  </>;
}

export default User;