import styled from 'styled-components';

export const Tickets = styled.div``;

export const TicketsGrid = styled.div`
  margin-top: 1rem;
  & > div {
    /* border-left: 3px solid ${props => props.theme.colors.primary};
    padding-left: 1rem; */
    margin-bottom: 1rem;

    /* border-radius: 2px; */
    /* border: 1px #cccccc solid; */
    border-top: 4px solid ${props => props.theme.colors.primary};
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,0.1),0 0px 0 1px rgba(10,10,10,0.02);
    padding: 1.5rem 2rem;

    &:last-child {
      margin-bottom: 0;
    }

  }

  .divider {
    border-bottom: 1px solid ${props => props.theme.colors.primary};

    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .button-group {
    margin: 0.5rem 0;
    & > a {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const SubtleButton = styled.a`
  font-size: ${props => props.theme.fontSizes.small};
  font-family: ${props => props.theme.fonts.body};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.background};

  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  padding: 0.3rem 0.5rem;

  outline: none;

  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.background};
  }
`;