import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Select, { SingleValue, Theme as SelectTheme } from 'react-select';

import { useAuth } from './../contexts/auth';
import { Theme } from '../styles/Theme';

// Components
import Header from './../components/Header';

import * as styles from './../styles/PageStyles';

const selectTheme = (theme: SelectTheme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: Theme.colors.primaryFaded,
    primary: Theme.colors.primary,
  },
});

const AddUser = () => {
  const { user, request } = useAuth();


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState({} as SingleValue<{ value: string; label: string; }>);
  
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');
  const [createdUser, setCreatedUser] = useState('');

  const addUser = async () => {

    setIsLoading(true);
    const response = await request('/users', 'POST', {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      status: status!.value,
    });
    const data = await response.json();
    if (response.ok) {
      setCreatedUser(data.id);
    } else {
      setError(data.error);
    }
    setIsLoading(false);
  }

  return <>
    <styles.PageWrapper>
      <Header />
      <styles.Apply id="info">
        <h3>Add a user</h3>
        <p>To add a user, fill out the form below.</p>
      </styles.Apply>

      { !createdUser &&
      <styles.FormPage>
        <form>
          <div>
            <label htmlFor="first_name">First name:</label>
            <input type="text" id="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </div>
          <div>
            <label htmlFor="last_name">Last name:</label>
            <input type="text" id="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div>
            <label htmlFor="password">Default password:</label>
            <input type="text" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div>
            <label htmlFor={'type'}>Account type:</label>
            <Select
              defaultValue={status}
              name={'type'}
              onChange={setStatus}
              theme={selectTheme}
              options={[
                { value: 'internal', label: 'Current St John\'s Student' },
                { value: 'alumni', label: 'St John\'s Alumni' },
                { value: 'external', label: 'Current cambridge student from a different college' },
                { value: 'fellow', label: 'Fellows and affliates' },
                { value: '1888', label: '1888 Club' },
                { value: 'other', label: 'Other' },
              ]}
            />
          </div>
          
          {error && <p className="error">{error}</p>}
          
          <div>
            <styles.SubtleButton onClick={addUser}>Add user</styles.SubtleButton>
          </div>
        </form>
      </styles.FormPage>
    }

    { createdUser &&
      <styles.Info>
        <h3>User created.</h3>
        <p><a href={`/user/${createdUser}`}>View user</a></p>
      </styles.Info>
    }
    </styles.PageWrapper>
  </>;
}

export default AddUser;