import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Select, { SingleValue, Theme as SelectTheme } from 'react-select';

import { useAuth } from '../contexts/auth';

// Components
import Header from './../components/Header';

import * as styles from '../styles/PageStyles';

const Homepage = () => {
  const { request, user } = useAuth();

  const getData = async () => {

  }

  useEffect(() => {
    getData();
  }, []);

  return <>
    <styles.PageWrapper>
      <Header />
      <styles.Apply id="info">
        <h3>St John's College May Ball Ticketing Admin</h3>
        <p>Admin site for the St John's May Ball Ticketing system. On here you should be able to find everything required for ticketing for the May Ball.</p>
        <p>If something isn't working, or something needs adding to the system please contact <a href="mailto:technology@mayball.org">Cameron</a>.</p>
      </styles.Apply>
    </styles.PageWrapper>
  </>;
}

export default Homepage;