import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Pages
import Login from './pages/Login';
import Logout from './pages/Logout';
import Homepage from './pages/Homepage';
import Stats from './pages/Stats';
import Users from './pages/Users';
import User from './pages/User';
import AddUser from './pages/AddUser';
import FellowVerification from './pages/FellowVerification';
import ClubVerification from './pages/ClubVerification';
import Accepted from './pages/Accepted';

// Components
// import Header from './components/Header';
// import Footer from './components/Footer';

// Contexts
import { AuthProvider, useAuth } from './contexts/auth';

// Styles
import { Theme } from './styles/Theme';
import { GlobalStyle } from './styles/GlobalStyle';

const AuthorisedRouter = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  
  const handleRedirect = () => {
    if (!isAuthenticated && !isLoading) {
      navigate('/login');
    }
  }

  useEffect(() => {
    handleRedirect();
  }, [isAuthenticated, isLoading]);
  
  return <>
    {isAuthenticated ? <>
      {/* <Header /> */}
            
      <div id="content">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/users" element={<Users />} />
          {/* <Route path="/accepted" element={<Accepted />} /> */}
          <Route path="/user/:id" element={<User />} />
          <Route path="/add_user" element={<AddUser />} />
          <Route path="/fellow_verification" element={<FellowVerification />} />
          <Route path="/club_verification" element={<ClubVerification />} />
        </Routes>
      </div>

      {/* <Footer /> */}
    </> : <></>
    }
  </>;
}

const App = () => {
  return <>
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login_callback" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={ <AuthorisedRouter /> } />
          </Routes>        
        </AuthProvider>
      </Router>
    </ThemeProvider>
  </>;
}

export default App;
